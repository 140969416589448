@import "./components/navigation";
@import "./components/button";
@import "./components/tables";
@import "./components/lines";
@import "./components/modal";
@import "./components/quiz-loader";
@import "~noty/src/noty.scss";
@import "~noty/src/themes/mint.scss";
@import "./components/alert";
@import "./components/text";
@import "./components/dashboard";
@import "./components/progress";
@import "./components/loader";
@import "./components/carousel";
@import "./components/custom-toast";

html {
  font-size: 62.5%;
}

body {
  font-family: "Open Sans", sans-serif, Helvetica Neue, 'Inter';
  font-size: 1.6rem;
  line-height: 1.15;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[v-cloak] {
  display: none
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 114rem;

  &--spaceless {
    @media (max-width: 1280px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &--full {
    padding-right: 0;
    padding-left: 0;
    max-width: 144rem;
  }
  &--checkout {
    max-width: 111rem;
    padding-right: 0px;
    padding-left: 0px;
    @media(max-width: 768px) {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
  &--wide {
    margin-left: auto;
    margin-right: auto;
    max-width: 127rem;
    padding: 0 1.5rem;
  }
  &--simple {
    margin-left: auto;
    margin-right: auto;
    max-width: 110rem;
    @media(max-width: 768px) {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
  &--simple-text {
    max-width: 90rem;
  }
  &--offer {
    max-width: 113rem;
  }
  &--tablet {
    max-width: 93rem;
  }
  &--smaller-width-img {
    img.w-20 {
      width: 5rem;
    }
    @media (min-width: 768px) {
      img.md\:w-32 {
        width: 6rem;
      }
    }
  }
}

@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@media (max-width: 768px) {
  .bg-hide {
    background-image: none !important;
  }
}

@media (min-width: 768px) {
  .bg-hide-desktop {
    background-image: none !important;
  }
}

.dot {
  width: 12px; height: 12px; border-radius: 50%;
  display: inline-block;
}


@import "./components/transitions";

.pb-full {
  padding-bottom: 100%;
}
@responsive {
  .pb-40 {
    padding-bottom: 40%;
  }
  .pb-56 {
    padding-bottom: 56%;
  }
}


.bg-blue-65 {
  background: #eff6fe;
}
.text-shadow {
  text-shadow: 0 0.1rem 0.4rem #2d2d2d;
}
@responsive {
  .py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}
.hero-left {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -1.3rem;
    width: 1.5rem;
    height: 100%;
    background-color: rgba(74,144,226,.13);
    background-image: linear-gradient(#2768b3,rgba(0,0,0,.1));
    @media (max-width: 768px) {
     display: none;
    }
  }
}
.video-info {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: -.5rem;
    left: 0;
    height: .5rem;
    width: 100%;
    background: #2766b0;
    opacity: .5;
  }
}

.max-w-80 {
  max-width: 80rem;
}
.max-w-91 {
  max-width: 91rem;
}
.bg-paypal {
  background: #FEC349;
}
.bg-gray-101 {
  background-color: #F0F0F0;
}

.bg-blue-2 {
  background-color: rgba(18, 84, 162, 0.35);
}
.border-gray-99 {
  border-color: #E2E2E2;
}
.border-gray-98 {
  border-color: #E9E9E9;
}

.text-gray-401 {
  color: #888888;
}
.text-gray-402 {
  color: #5C5C5C;
}

.text-black-1 {
  color: #171616
}
.text-black-2 {
  color: #171717;
}

.text-black-3 {
  color: #1A1919;
}

.bg-green-1 {
  background-color: #08B335;
}
.bg-blue-3 {
  background-color: #0A52A4;
}
.bg-blue-4 {
  background-color: #274F8D;
}

.text-blue-2 {
  color: #0E437B;
}

.text-green-2 {
  color: #255E09;
}

.text-gray-700-8 {
  color: rgba(#605D5D, 0.8)
}

.text-gray-1 {
  color: #605D5D;
}
.text-blue-1 {
  color: #0B4279;
}

.friend-ribbon {
  top: -0.5rem; right: -1.5rem;
  @media (max-width: 768px) {
    top: -1.4rem;
    right: -0.8rem;
  }
}
.friend-arrow {
  top: 1.5rem; left: 0;
  @media (max-width: 768px) {
    top: 1.2rem;
    left: -0.5rem ;
  }
}

.svg {
  svg {
    max-width: 100%;
  }
}

.checkout-bg-image {
  background-image: url('/images/bg_checkout_1.png');

  @media (max-width: 768px) {
    background-image: url('/images/bg_checkout_1_mobile.png');
  }
}

.top-0i {
  top: 0 !important;
}
.search-input::-ms-clear {  display: none; width : 0; height: 0; }
.search-input::-ms-reveal {  display: none; width : 0; height: 0; }

.alternative-preselect-price {
  height: 89%;
}

::-ms-clear {
  display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie {
    &__image-holder {
      img {
        width: 100%;
      }
    }
  }
}

.fav-store {
  height: 14.4rem;
  @media (max-width: 768px) {
    height: 14.5rem;
  }
}

@media (min-width: 1024px) {
  .blue-bg-chopped {
    background: linear-gradient(106deg, #fff 55%, #D5F0FB 44%);
  }

  .pink-bg-chopped {
    background: linear-gradient(105deg, #fff 54%, #DFDEEF 54%);
  }

  .green-bg-chopped {
    background: linear-gradient(105deg, #fff 63%, #E3EFDE 63%);
  }
}